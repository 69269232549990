import React from 'react'

export default () =>
  <footer className="container">
    <p>
      &copy; Andie Draper {new Date().getYear() + 1900}
      &nbsp;• <a href="http://twitter.com/andiesmakes" target="_blank">Twitter</a>
      &nbsp;• <a href="https://kickcode.com" target="_blank">KickCode</a>
    </p>
  </footer>
